import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function isIE() {
  const ua = navigator.userAgent;
  const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return is_ie;
}

if (isIE()) {
  alert(
    "L'accès au portail HubEE n'est pas possible avec Internet Explorer, veuillez utiliser un autre navigateur"
  );
} else {
  if (environment.production === true || environment.production === 'true') {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}
